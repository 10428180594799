import $ from 'jquery'
import magnificPopup from 'magnific-popup'
const  $doc = $(document)

// this is just an example of functionality, opens only one static popup with #popup ID
function openModal(e) {
  const targetModal = e.target.dataset.targetModal;
  e.preventDefault();

  $.magnificPopup.open({
    items: {
      src: `#${targetModal}`,
      type: 'inline',
    },
    closeBtnInside: true
  });
}

export default function initMagnificPopup(){
  $doc.on('click', '.js-open-modal', openModal );
}
