import $ from 'jquery'
const $doc = $(document)

function openDonateForm(event) {
  event.preventDefault()

  let box = $(event.currentTarget)
  $('.js-donate-box').removeClass('is-active')
  box.addClass('is-active')

  $('#donate__form:not(.is-active)').addClass('is-active');

  $('#gform_wrapper_4 > .gform_heading > .gform_amount').remove()
  var amount = box.find('.js-donate-amount').html()

  $('#gform_wrapper_4 > .gform_heading').append('<h3 class="gform_amount">'+amount+'</h3>')
  $('input[name="input_31"]').val(amount.substring(1))
  
}

export default function initDonate(){
  $doc.on('click', '.js-donate-box', openDonateForm )
}
