// import Headroom from 'headroom.js';

// let header = document.querySelector('.js-header')

// function checkWindow() {
//   if ($(window).width() >= 960) {
//     header = document.querySelector('.js-header-translate');
//   }
//   loadHeadroom(header);

// }

// function loadHeadroom(header) {
//   const headroomHeader = new Headroom(header)
//   headroomHeader.init();
// }

// export default function initHeadroom(){
//   $(document).ready( checkWindow );

//   $(window).on('scroll', function() {
//     $('.js-header').addClass('is-over');
//     if ($(window).scrollTop() == 0) {
//       $('.js-header').removeClass('is-over');
//     }
//   });

// }

import Headroom from 'headroom.js'

export default function initHeadroom(){
  const theHeader = document.querySelector('.js-header')
  const headroomHeader = new Headroom(theHeader)
  headroomHeader.init()
}

