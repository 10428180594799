import $ from 'jquery'

const $doc = $(document)
const $body = $('body')
const $mobileNav = $('.js-mobile-nav')
const $hamburger = $('.js-hamburger')
const $mobileNavSub = $('.js-mobile-nav .menu-item-has-children')
var $scrollPos = '';

function showMobileNav(event) {
  event.preventDefault()
  $mobileNav.toggleClass('is-active')
  $hamburger.toggleClass('is-active')
  if ( $mobileNav.hasClass('is-active')) {
    $hamburger.attr('aria-expanded', 'true')
    const scrollY = document.documentElement.style.getPropertyValue('--scroll-y');
    const body = document.body;
    body.style.position = 'fixed';
    body.style.top = `-${scrollY}`;
  } else {
    $hamburger.attr('aria-expanded', 'false')
    const body = document.body;
    const scrollY = body.style.top;
    body.style.position = '';
    body.style.top = '';
    window.scrollTo(0, parseInt(scrollY || '0') * -1);
  }
}

//Mobile nav dropdowns functionality with accessability features added
function showMobileSubNav(event) {
  // event.preventDefault();
  var el = $(event.currentTarget);
  el.toggleClass('is-active');
  if (el.parent().hasClass('is-active')) {
    el.parent().attr("aria-pressed", "true");
    el.parent().attr("aria-expanded", "true");
  } else {
    el.parent().removeAttr("aria-pressed");
    el.parent().attr("aria-expanded", "false");
  }
}

export default function initMobileNav(){
  window.addEventListener('scroll', () => {
    document.documentElement.style.setProperty('--scroll-y', `${window.scrollY}px`);
  });
  $doc.on('click', '.js-mobile-nav .menu-item-has-children', showMobileSubNav )
  $doc.on('click', '.js-hamburger', showMobileNav )
  $doc.on('keydown', function(event) {
    if ( $mobileNav.hasClass('is-active') && (event.key == "Escape") ) {
      $hamburger.attr('aria-expanded', 'false')
      $mobileNav.toggleClass('is-active')
      $hamburger.toggleClass('is-active')
      const body = document.body;
      const scrollY = body.style.top;
      body.style.position = '';
      body.style.top = '';
      window.scrollTo(0, parseInt(scrollY || '0') * -1);
      // $body.toggleClass('mobilenav-is-active')
      // scrollTop: target.offset().top - 200
    }
  })
  $doc.on('focusin', function (event) {
    var $target = $(event.target);
    if (!$target.closest('.js-header-wrapper').length) {
      if ( $mobileNav.hasClass('is-active') ) {
        $hamburger.attr('aria-expanded', 'false')
        $mobileNav.toggleClass('is-active')
        $hamburger.toggleClass('is-active')
        const body = document.body;
        const scrollY = body.style.top;
        body.style.position = '';
        body.style.top = '';
        window.scrollTo(0, parseInt(scrollY || '0') * -1);
        // $body.toggleClass('mobilenav-is-active')
      }
    }
  })
}

